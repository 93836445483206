
.sidebar-widget-title {
  background: #EEEEEE;
  font-size: 1.5rem;
  padding: 1rem;
}

.sidebar-empty-row {
  padding: 1rem;
}
