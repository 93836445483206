
.sidebar-order {
  padding: 1rem;
  border-bottom: 1px solid #EEEEEE;
}

.sidebar-order-number {
  padding-bottom: .5rem;
}

.sidebar-order-priority {
  padding-bottom: .5rem;
}
