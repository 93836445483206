
.sidebar-status-entry {
  padding: 1rem;
}

.sidebar-status-entry-title {
  display: inline-block;
  width: 8rem;
}

.sidebar-status-entry-value {
  display: inline-block;
  font-weight: bold;
}
