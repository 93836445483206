
.zip-clock {
  border-radius: 1rem;
  padding: .5rem 1rem;
  background: white;
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  font-family: monospace;
  align-items: center;
  user-select: none;
}

.zip-clock-time {
  flex: 0 0 auto;
  border-right: 1px solid #AAAAAA;
  padding-right: 1rem;
}

.zip-clock-speed {
  flex: 0 0 auto;
  padding: 0 1rem;
}

.zip-clock-speed-control {
  cursor: pointer;
  border-radius: .5rem;
  padding: .25rem .5rem;
}

.zip-clock-speed-control:hover {
  background: #EEEEEE;
}
