
.flight-path-line {
  stroke: blue;
  stroke-width: 2;
  stroke-dasharray: 6 3;
  stroke-dashoffset: 0;
  animation: dash-animation 0.5s linear infinite;
}

.flight-path-line.flight-path-line-emergency {
  stroke: red;
}

@keyframes dash-animation {
  to {
    stroke-dashoffset: -9;
  }
}

.flight-path-zip {
  fill: blue;
}

.flight-path-zip.flight-path-zip-emergency {
  fill: red;
}
