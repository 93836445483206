body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F9F7D7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, .app-wrap, .app-svg, .app-svg-wrap {
  height: 100%;
}

.app-wrap {
  display: flex;
}

.app-svg-wrap {
  flex: 1;
}

.app-sidebar-wrap {
  background: white;
  flex: 0 0 30rem;
}

.app-svg {
  width: 100%;
}

.mobile-tab {
  display: none;
}

@media screen and (max-width: 800px) {
  .app-wrap {
    display: block;
  }

  .mobile-tab {
    display: block;
    margin: auto;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 10rem;
    text-align: center;
    padding: .5rem;
    cursor: pointer;
  }
}
