
.zip-hospital {
  fill: gray;
}

.zip-hospital-active-resupply {
  fill: blue;
}

.zip-hospital-active-emergency {
  fill: red;
}
