
.sidebar-flight {
  padding: 1rem;
  border-bottom: 1px solid #EEEEEE;
}

.sidebar-flight-number {
  padding-bottom: .5rem;
}

.sidebar-flight-priority {
  padding-bottom: .5rem;
}

.sidebar-flight-destination-item {
  padding-right: .5rem;
}
